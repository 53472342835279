/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Link} from "gatsby";
import MapPreview from "../../components/mapPreview";
import BookingSection from "../../components/bookingSection";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {LandmarkCards} = _components;
  if (!LandmarkCards) _missingMdxReference("LandmarkCards", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Nous vous proposons de partir en week-end à Bratislava, avec les incontournables pour passer quelques jours dans la capitale slovaque. Nous verrons d’abord quand y aller, comment s’y rendre, ce qu’il faut visiter, mais aussi dans ", React.createElement(Link, {
    to: "/se-loger-a-bratislava"
  }, "quels quartiers se loger"), ", manger et sortir à Bratislava en deux jours."), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "Quand aller à Bratislava ?"), React.createElement(_components.p, null, "La période idéale pour découvrir Bratislava se situe entre mai et septembre avec un bon ensoleillement malgré des précipitations importantes. L’affluence touristique est bien plus grande en juillet et en août. Le climat continental entraîne des écarts de températures importants, avec des hivers assez rigoureux et plutôt longs, en particulier un mois de janvier très froid, et un été chaud. Pour les passionnés de ski, les stations slovaques sont souvent ouvertes dès novembre.")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "Se repérer et se déplacer à Bratislava"), React.createElement(_components.p, null, "Les deux gares de Bratislava sont bien desservies par les transports en commun ou le taxi. Au-delà des petites rues piétonnes du centre-ville, il vous faudra grimper pour aller jusqu’au château et sortir un peu du centre pour voir l’église bleue."), React.createElement(MapPreview, {
    city: "Bratislava",
    normalizedCityName: "bratislava",
    type: "must-see"
  })), "\n", React.createElement(BookingSection, {
    city: "Bratislava",
    normalizedCityName: "bratislava"
  }), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "A visiter à Bratislava"), React.createElement(LandmarkCards)), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "Sortir à Bratislava"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "La plupart des bars du Korso (centre piéton de la ville) sont très fréquentés par les Slovaques (surtout pendant les semestres universitaires)."), "\n", React.createElement(_components.li, null, "Le long de Michalska Ulica et Venturska Ulica s’étend une succession de bars animés, dans un quartier surnommé le « Broadway slovaque »."), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "Faire du shopping à Bratislava"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Obchodna est la rue principale pour le shopping, elle se trouve juste à côté du centre piéton. Les centres commerciaux sont nombreux comme Eurovea Aupark ou Polus."), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "Manger à Bratislava"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Prasna Basta, Zalmonicka, 11 - Dans un passage, à proximité de la porte Michel, un des meilleurs rapports qualité-prix de la ville pour découvrir la cuisine slovaque."), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "Poursuivre son voyage après Bratislava"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Les principales villes touristiques en Slovaquie sont ", React.createElement("a", {
    href: "https://www.booking.com/city/sk/kosice.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Košice"), ", ", React.createElement("a", {
    href: "https://www.booking.com/city/sk/poprad.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Poprad"), ", ", React.createElement("a", {
    href: "https://www.booking.com/city/sk/vysoke-tatry.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Vysoké Tatry"), ", ", React.createElement("a", {
    href: "https://www.booking.com/city/sk/tatranska-lomnica.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Tatranská Lomnica"), " et ", React.createElement("a", {
    href: "https://www.booking.com/city/sk/liptovsky-mikulas.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Liptovský Mikuláš"), "."), "\n", React.createElement(_components.li, null, "Les villes européennes les plus proches de Bratislava sont ", React.createElement(Link, {
    to: "/week-end-a-vienne-les-incontournables"
  }, "Vienne en Autriche"), ", ", React.createElement(Link, {
    to: "/week-end-a-budapest-les-incontournables"
  }, "Budapest en Hongrie"), ", ", React.createElement(Link, {
    to: "/week-end-a-zagreb-les-incontournables"
  }, "Zagreb en Croatie"), ", ", React.createElement(Link, {
    to: "/week-end-a-prague-les-incontournables"
  }, "Prague en République tchèque"), ", ", React.createElement(Link, {
    to: "/week-end-a-cracovie-les-incontournables"
  }, "Cracovie en Pologne"), " et ", React.createElement(Link, {
    to: "/week-end-a-ljubljana-les-incontournables"
  }, "Ljubljana en Slovénie"), "."), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "Pour aller plus loin"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: "https://www.amazon.fr/s/ref=as_li_ss_tl?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&url=search-alias=stripbooks&field-keywords=Petit+Fut%C3%A9+Slovaquie&rh=n:301061,k:Petit+Fut%C3%A9+Slovaquie&linkCode=ll2&tag=pietondelair-21&linkId=3a357c9c3666e9b008443a60a547aee6",
    target: "_blank",
    rel: "nofollow noopener noreferrer"
  }, React.createElement("span", null, "Petit Futé Slovaquie")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: "https://www.amazon.fr/s/ref=as_li_ss_tl?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&url=search-alias=stripbooks&field-keywords=Guide+du+Routard+Slovaquie&rh=n:301061,k:Guide+du+Routard+Slovaquie&linkCode=ll2&tag=pietondelair-21&linkId=93bcaf1450dbf76dc4fe513fe2c64b23",
    target: "_blank",
    rel: "nofollow noopener noreferrer"
  }, React.createElement("span", null, "Guide du Routard République tchèque et Slovaquie")), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
